<template>
  <div>
    <div :style="{backgroundImage:`url(${require('@/assets/resource/about-bottom-banner.png')})`}" class="contact-banner wow fadeInUp">
      <div class="banner-cont">
        <div class="desc">十年深耕，专业打造精益数字化产品帮助企业塑造优化品牌形象，提升商业价值</div>
        <div class="btn-cont">
          <div class="btn active" @click="popupVisible = true">立即咨询</div>
          <div class="btn" @click="popupVisible = true">获取报价</div>
        </div>
      </div>
    </div>

    <contact-popup v-model="popupVisible"></contact-popup>
  </div>

</template>

<script>

import ContactPopup from "@/components/contactPopup";
export default {
  name: "contact-banner",
  components: {ContactPopup},
  data() {
    return {
      popupVisible: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-banner {
  width: 100%;
  height: 37rem !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .banner-cont {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19rem;

    .desc {
      font-size: 3rem;
      color: #ffffff;
    }

    .btn-cont {
      display: flex;
      justify-content: flex-start;
      margin-top: 5rem;

      .btn {
        width: 15rem;
        height: 5rem;
        border: .1rem solid #0044C2;
        font-size: 1.8rem;
        color: #ffffff;
        text-align: center;
        line-height: 5rem;
        margin-right: 1.5rem;
        cursor: pointer;

        &.active {
          background-color: #0044C2;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-banner{
    .banner-cont{
      width: 80%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
</style>
