<template>
  <div class="case-list">
    <div v-for="(item,index) in caseList" :key="index" :data-wow-delay="index/10 + 's'" class="case-item wow fadeInUp" @click="toCaseDetailPage(item.id)">
      <div :style="{backgroundImage:`url(${item.thumb_image})`}" class="image-cont">
        <div class="mask">{{ item.title }}</div>
      </div>
    </div>

    <template v-if="moreBtn !== 'none'">
      <div class="layout cx-fex-c cx-fex-itemsc" style="margin-top: 8.6rem;margin-bottom: 3rem">
        <div class="more-btn" @click="toCasePage()">MORE</div>
      </div>
    </template>

  </div>
</template>

<script>
export default {
  name: "index-case-list2",
  props: {
    caseList: {
      type: Array,
      default: () => ([])
    },
    moreBtn: {
      type: String,
      default: 'btn', //btn:按钮   scroll:滚动   none: 不显示
    }
  },
  methods: {
    toCasePage() {
      this.$router.push({
        path: '/case'
      })
    },
    toCaseDetailPage(id) {
      this.$router.push({
        path: '/caseDetail',
        query:{
          id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.case-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .case-item {
    width: calc(25% - 2rem);
    margin: 1rem;

    .image-cont {
      width: 100%;
      padding-bottom: 83.626%;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      transition: all 0.5s;

      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all 0.5s;

      }

      &:hover {
        background-size: 120% 120%;

        .mask {
          opacity: 0.6;
        }
      }
    }
  }

  .more-btn {
    width: 28.5rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;
    border: .1rem solid #cccccc;
    font-size: 1.6rem;
    color: #cccccc;
    cursor: pointer;
  }
}


@media screen and (max-width: 768px) {
  .case-list {
    .case-item{
      width: 100%;
    }
  }
}
</style>
