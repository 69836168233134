<template>
  <div class="banner-cont">
    <div
        v-for="(item,index) in bannerList"
        :key="index"
        :class="{active:currentBannerIndex === index}"
        :style="{backgroundImage:`url(${item.image})`}"
        class="banner-item"
    >
      <div v-if="currentBannerIndex === index && !isMobile" class="title-cont" >
        <div v-for="(title,titleIndex) in item.title.split(' ')" :key="titleIndex" class="title">
          <div v-for="(titleItem,itemIndex) in title.split('')" :key="itemIndex" :style="{animationDelay:`${itemIndex/10}s`}" class="animated fadeInUp">
            {{ titleItem }}
          </div>
        </div>
        <div class="sub-title">{{ item.desc }}</div>
      </div>
    </div>

    <div class="banner-index-cont">
      <span>{{ $tool.numberToDouble(currentBannerIndex + 1) }} / </span>{{ $tool.numberToDouble(bannerList.length) }}
    </div>
    <div class="progress-cont">
      <div :style="{width:`${progressBarWidth}%`}" class="progress-bar"></div>
    </div>


    <div class="page-cont">
      <img alt="" src="@/assets/images/index-banner-prev.png" @click="changeCurrentBannerIndex('reduce')">
      <div>Next</div>
      <img alt="" src="@/assets/images/index-banner-next.png" @click="changeCurrentBannerIndex('add')">
    </div>

    <div class="scroll-down">
      <div class="word">Scroll Down</div>
      <div class="icon-cont">
        <div class="triangle"></div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

let changeTimer = null;
import {WOW} from 'wowjs'

export default {
  name: "indexBannerCont",
  props: {
    changeSecond: {
      type: Number,
      default: 4,
    },
    bannerList: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      currentBannerIndex: 0,
      progressBarWidth: 0,
    }
  },
  computed:{
    ...mapState(['isMobile'])
  },
  watch: {
    currentBannerIndex: {
      immediate: true,
      handler(e) {
        clearInterval(changeTimer);
        const second = this.changeSecond * 1000;
        let reduceSecond = this.changeSecond * 1000
        changeTimer = setInterval(() => {
          if (reduceSecond <= 0) {
            clearInterval(changeTimer);
            this.currentBannerIndex++;
            if (this.currentBannerIndex >= this.bannerList.length) {
              this.currentBannerIndex = 0
            }
            return false;
          }
          reduceSecond -= 10;
          this.progressBarWidth = (second - reduceSecond) / second * 100;
        }, 10)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      new WOW().init();
    })
  },
  methods: {
    changeCurrentBannerIndex(type) {
      clearInterval(changeTimer);
      if (type === 'add') {
        if (this.currentBannerIndex >= this.bannerList.length - 1) {
          this.currentBannerIndex = 0;
          return false;
        }
        this.currentBannerIndex++;
      } else if (type === 'reduce') {
        if (this.currentBannerIndex <= 0) {
          this.currentBannerIndex = this.bannerList.length - 1;
          return false;
        }
        this.currentBannerIndex--;
      }
    }
  }

}
</script>

<style lang="scss" scoped>
@keyframes moveTopBottom {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

.banner-cont {
  width: 100%;
  height: 100%;
  position: relative;

  .banner-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: all 1s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      z-index: 10;
      opacity: 1;
    }

    .title-cont {
      color: #ffffff;
      position: absolute;
      left: 15rem;
      top: 40rem;
      z-index: 100;

      .title {
        line-height: 1;
        margin-top: 1rem;
        display: flex;
        //font-family: "SourceHanSansCN-Bold";

        & > div {
          font-size: 7.2rem;
          font-weight: 500;
          text-shadow: 0 0 4rem rgba(0, 0, 0, 1);
        }


      }

      .sub-title {
        font-size: 1.8rem;
        margin-top: 2.5rem;
      }
    }
  }

  .banner-index-cont {
    position: absolute;
    left: 18.8rem;
    top: 24rem;
    font-size: 1.8rem;
    color: #818080;
    z-index: 100;

    span {
      font-size: 3rem;
      color: #ffffff;
    }
  }


  .progress-cont {
    width: 28rem;
    height: .2rem;
    background-color: #444445;
    position: absolute;
    left: 0;
    top: 30rem;
    z-index: 100;

    .progress-bar {
      width: 2rem;
      height: 100%;
      background-color: #005AAB;
    }
  }

  .page-cont {
    position: absolute;
    right: 6rem;
    bottom: 3.2rem;
    z-index: 20;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > div {
      height: 3.3rem;
      margin: 0 3rem;
      padding-left: 2.7rem;
      border-left: .2rem solid #ffffff;
      font-size: 1.8rem;
      color: #ffffff;
      line-height: 1.5;
      cursor: pointer;
    }

    img {
      cursor: pointer;
    }
  }

  .scroll-down {
    position: absolute;
    left: 6.7rem;
    bottom: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .word {
      color: #58595B;
      font-size: 1.6rem;
      writing-mode: vertical-rl;
      text-orientation: sideways;
    }

    .icon-cont {
      animation: moveTopBottom 1.5s ease-in-out infinite;
      margin-top: 2rem;
      margin-right: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .triangle {
        width: 0;
        height: 0;
        border: 0.6rem solid transparent;
        border-top-color: #005AAB;
      }

      .line {
        transform: translateY(-0.6rem);
        width: .1rem;
        height: 17.4rem;
        background-color: #444445;
      }
    }
  }
}
</style>
