<template>
  <div class="section-03">
    <div class="service-cont wow fadeInUp">
      <!--      <div :style="{backgroundImage:`url(${require('@/assets/resource/about-environment-01.png')})`}" class="image-cont"></div>-->
      <div ref="businessSwiper" class="swiper-container business-swiper">
        <div class="swiper-wrapper">
          <div v-for="(item,index) in serviceList" :key="index" class="swiper-slide">
            <div :style="{backgroundImage:`url(${item.business[0].image})`}" class="image-cont"></div>
          </div>
        </div>
      </div>

      <div class="right-cont" v-if="!isMobile">
        <div class="list">
          <div class="line"></div>
          <div
              v-for="(item,index) in serviceList"
              :key="index"
              :class="{active: currentBusinessIndex === index}"
              class="item"
              @click="changeCurrentBusinessIndex(index)"
          >
            <div class="box"></div>
            <div :style="{backgroundImage:`url(${require('@/assets/images/index-service-item-bg.png')})`}" class="item-image-cont">
              <img :src="item.icon_image" alt="">
            </div>
            <div class="title">{{ item.cate_name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getBusinessList} from "@/request/api";
import Swiper from 'swiper';
import {mapState} from "vuex";

export default {
  name: "service-cont",
  data() {
    return {
      serviceList: [],
      swiper: null,
      currentBusinessIndex: 0,
    }
  },
  computed:{
    ...mapState(['isMobile'])
  },
  mounted() {
    this.getBusinessList();
  },
  methods: {
    renderSwiper() {
      const _this = this;
      this.swiper = new Swiper('.business-swiper', {
        direction: 'vertical',
        on: {
          slideChange: function () {
            _this.$set(_this, 'currentBusinessIndex', this.realIndex)
          }
        }
      })
    },
    changeCurrentBusinessIndex(index) {
      // console.log(this.$refs.flowPathSwiper.swiper.realIndex)
      this.currentBusinessIndex = index
      // this.$refs.flowPathSwiper.swiper.slideTo(index, 300, false)
      const loopedSlides = this.$refs.businessSwiper.swiper.loopedSlides || this.$refs.businessSwiper.swiper.params.loopedSlides // 获取 loopedSlides 数量
      const slideToIndex = this.currentBusinessIndex + loopedSlides // 转换为实际索引
      this.$refs.businessSwiper.swiper.slideTo(slideToIndex, 300, false) // 跳转到指定 slide
      this.$refs.businessSwiper.swiper.emit('slideChangeEnd') // 手动触发 onSlideChangeEnd 回调函数
    },
    async getBusinessList() {
      let res = await getBusinessList();
      console.log(res);
      this.serviceList = res.data;
      this.$nextTick(() => {
        this.renderSwiper();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-03 {
  background-color: #f2f2f2;
  padding: 11rem;

  .service-cont {
    width: 100%;
    height: 78rem;
    display: flex;
    justify-content: flex-start;

    .business-swiper {
      width: 122.5rem;
      height: 100%;
    }

    .image-cont {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .right-cont {
      width: 47.5rem;
      background-color: #ffffff;
      padding: 0 5rem 0 6rem;
      height: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0;
      }

      .list {
        padding-top: 7rem;
        padding-left: 4rem;
        position: relative;

        .line{
          position: absolute;
          top: 12rem;
          left: 0;
          height: calc(19.5rem * 4 - 6rem);
          width: .1rem;
          background-color: #999999;
        }

        .item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 7rem;
          cursor: pointer;
          position: relative;

          .box{
            width: 1.2rem;
            height: 1.2rem;
            background-color: #005AAB;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -4.6rem;
          }
          .item-image-cont {
            width: 12.5rem;
            height: 10.9rem;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              max-height: 50%;
              max-width: 50%;
            }
          }

          .title {
            font-size: 1.6rem;
            margin-left: 5rem;
          }

          &.active {
            .title {
              color: #005AAB;
            }

          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .section-03{
    padding: 5rem;
  }
}
</style>
