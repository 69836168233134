import Vue from 'vue'
import Vuex from 'vuex'
import localStorage from "@/utils/localStorage";
import globalData from "@/../public/globalData";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMobile: false,
        navigationList: [
            {name: 'Home 首页', path: '/'},
            {name: 'Case 案例', path: '/case'},
            {name: 'R&D 研发中心', path: '/produce'},
            {name: 'News 智库', path: '/news'},
            {name: 'Service 服务', path: '/service'},
            {name: 'Contact 联系', path: '/contact'},
            {name: 'About 关于', path: '/about'},
        ],
        systemConfig: {},
        globalData: globalData,
        bannerData: null,
        metaInfoData: localStorage.get('metaInfoData') || null,
    },
    getters: {},
    mutations: {
        setIsMobile: (state, payload) => {
            state.isMobile = payload;
        },
        setSystemConfig: (state, payload) => {
            state.systemConfig = payload;
        },
        setBannerData: (state, payload) => {
            state.bannerData = payload;
        },
        setMetaInfoData: (state, payload) => {
            state.metaInfoData = payload;
        }

    },
    actions: {},
    modules: {}
})
