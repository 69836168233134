<template>
  <div id="app">
    <custom-header></custom-header>
    <router-view/>
    <custom-footer v-if="$route.path !== '/contact'"></custom-footer>
  </div>
</template>

<script>
import {remFn} from "@/utils/rem";
import CustomFooter from "@/components/custom-footer";
import CustomHeader from "@/components/custom-header";
import {getBannerList, getSeoList, getSystemConfig} from "@/request/api";
import localStorage from "@/utils/localStorage";

export default {
  components: {CustomHeader, CustomFooter},
  mounted() {
    process.env.NODE_ENV === 'production' && this.disabledSomething();
    this.reloadRem();
    window.addEventListener("resize", this.reloadRem, {passive: false});
    this.getSeoList();
    this.getSystemConfig();
    this.getBannerData();
  },
  methods: {
    disabledSomething() {
      // 网页禁止复制文字
      document.oncontextmenu = new Function("event.returnValue=false");
      document.onselectstart = new Function("event.returnValue=false");
      document.onkeydown = () => {
        //禁用F12
        if (window.event && window.event.keyCode == 123) {
          return false;
        }
      }
    },
    async getSystemConfig() {
      let res = await getSystemConfig();
      this.$store.commit("setSystemConfig", res.data);
    },
    async getBannerData() {
      let res = await getBannerList();
      this.$store.commit("setBannerData", res.data);
    },
    reloadRem() {
      let windowWidth = document.documentElement.getBoundingClientRect().width;
      if (windowWidth <= 750) {
        this.$store.commit('setIsMobile', true);
        remFn(968, 1024)
      } else if (windowWidth < 1024 && windowWidth > 750) {
        this.$store.commit('setIsMobile', true);
        remFn(1920, 1920)
      } else {
        this.$store.commit('setIsMobile', false);
        remFn(1920, 3840)
      }
    },
    async getSeoList() {
      let res = await getSeoList();
      this.$store.commit('setMetaInfoData', res.data);
      localStorage.set('metaInfoData', res.data);
    }
  }
}
</script>

<style lang="scss">

</style>
