<template>
  <div class="__section-main__" style="background-color: #FAF8F5;">
    <div class="container" v-if="detail" :style="{padding:`${isMobile? '20rem 5rem 5rem' : '20rem 36rem 5rem'}`}">
      <div class="detail-main">
        <div class="cx-fex cx-fex-itemsc">
          <div class="cx-fex-l cx-fex-column" style="flex: 1">
            <div class="title">{{ detail.title }}</div>
            <div class="time">发表日期：{{ $tool.getTimeStr(detail.updatetime * 1000) }}</div>
          </div>

<!--          <div class="back" @click="$router.back()">-->
<!--            <i class="cx-icon cx-fanhui"></i>返回-->
<!--          </div>-->
        </div>
        <div class="line"></div>

        <div class="content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsDetail} from "@/request/api"
import {mapState} from "vuex";
export default {
  data() {
    return {
      detail:null
    }
  },
  computed:{
    ...mapState(['isMobile'])
  },
  mounted() {
    const newsId = this.$route.query.id ? this.$route.query.id : "";
    this.getNewsDetail(newsId);
  },
  methods:{
    async getNewsDetail(id) {
      let res = await getNewsDetail({id});
      this.detail = res.data;
    }
  }
}
</script>

<style scoped lang="scss">
.detail-main {
  padding: 3rem 5rem 6rem;
  background-color: #ffffff;

  .title {
    font-size: 2.4rem;
    color: #333333;
  }

  .time {
    font-size: 1.6rem;
    color: #999999;
    margin-top: 2.5rem;
  }

  .back {
    padding: 0 4rem;
    color: var(--default-color);
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;

    i {
      margin-right: 1rem;
      font-size: 1.8rem;
    }
  }

  .line {
    width: 100%;
    height: .1rem;
    background-color: #f8f8f8;
    margin: 3rem 0;
  }

  .content {
    & > div {
      font-size: 1.6rem;
      color: #666666;
      line-height: 4.8rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .detail-main {
    padding: 3rem 5rem 6rem;
    background-color: #ffffff;

    .title {
      font-size: 5rem;
      line-height: 1.5;
    }

    .time {
      font-size: 3.4rem;
      margin-top: 3.5rem;
    }

    .back {
      display: none !important;
    }
  }

}
</style>
