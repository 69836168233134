import Vue from 'vue'
import VueRouter from 'vue-router'

// 保存原来的push函数
const routerPush = VueRouter.prototype.push
// 重写push函数
VueRouter.prototype.push = function push(location) {
    // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
    // 用来触发watch
    if (typeof (location) == "string") {
        var Separator = "&";
        if (location.indexOf('?') == -1) {
            Separator = '?';
        }
        location = location + Separator + "random=" + Math.random();
    }
    // 这个语句用来解决报错
    // 调用原来的push函数，并捕获异常
    return routerPush.call(this, location).catch(error => error)
}

import homeIndex from '@/views/home/index'
import caseIndex from "@/views/case/index"
import caseDetail from '@/views/case/details'
import serviceIndex from "@/views/service/index"
import contactIndex from "@/views/contact/index"
import aboutIndex from "@/views/about/index"
import newsIndex from "@/views/news/index"
import newsDetail from "@/views/news/details"
import produceIndex from  "@/views/produce/index"

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: homeIndex
    },
    {
        path: '/case',
        name: 'case',
        component: caseIndex
    },
    {
        path: '/caseDetail',
        name: 'caseDetail',
        component: caseDetail
    },
    {
        path: '/produce',
        name: 'produce',
        component: produceIndex
    },
    {
        path: '/news',
        name: 'news',
        component: newsIndex
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        component: newsDetail
    },
    {
        path: '/service',
        name: 'service',
        component: serviceIndex
    },
    {
        path: '/contact',
        name: 'contact',
        component: contactIndex
    },
    {
        path: '/about',
        name: 'about',
        component: aboutIndex
    },

]

const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior: () => ({y: 0}), //滚动到顶端
})

export default router
