<template>
  <div class="case-list">
    <div v-for="(item,index) in caseList" :key="index" :data-wow-delay="index/10 + 's'" class="case-item wow fadeInUp" @click="toCaseDetailPage(item.id)">
      <div :style="{backgroundImage:`url(${item.thumb_image})`}" class="image-cont"></div>
      <div class="desc">{{ item.title }}</div>
      <div class="title">{{ item.description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-case-list1",
  props: {
    caseList: {
      type: Array,
      default: () => ([]),
    }
  },
  methods: {
    toCaseDetailPage(id) {
      this.$router.push({
        path: '/caseDetail',
        query: {
          id,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.case-list {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .case-item {
    display: flex;
    flex-direction: column;
    transition: all 0.5s;
    width: calc(25% - 1rem);

    &:hover {
      transform: translateY(-2rem);
    }

    .image-cont {
      //width: 56rem;
      //height: 46.6rem;
      width: 100%;
      padding-bottom: 85%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    .desc {
      font-size: 1.4rem;
      color: #2c2c2c;
      margin-top: 4.2rem;
    }

    .title {
      font-size: 1.6rem;
      color: #2c2c2c;
      margin-top: 3rem;
      margin-bottom: 4rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .case-list {
    flex-wrap: wrap;
    .case-item{
      width: 100%;
    }
  }
}
</style>
