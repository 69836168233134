<template>
  <div :class="{active:(scrollTop >= 10 || $route.path === '/newsDetail')}" class="header-cont">
    <div v-if="!isMobile" class="content">
      <div class="header-logo">
        <img :src="(scrollTop >= 10 || $route.path === '/newsDetail') ? headerLogoActive : headerLogo" alt="">
      </div>
      <div class="nav-list">
        <router-link
            v-for="(item,index) in navigationList"
            :key="index"
            :class="{active:item.path===$route.path}"
            :to="{path:item.path}"
            class="nav-item"
            tag="div">
          {{ item.name }}
          <div class="line"></div>
        </router-link>
      </div>
      <div class="contact-cont">
        <img alt="" class="icon" src="@/assets/images/header-phone.png"/>
        <div class="phone">{{ systemConfig ? systemConfig.mobile_400 : '' }}</div>
      </div>
    </div>

    <div v-if="isMobile" class="mobile-content">
      <div class="cx-fex-l cx-fex-itemsc">
        <div class="header-logo">
          <img :src="(scrollTop >= 10 || $route.path === '/newsDetail') ? headerLogoActive : headerLogo" alt="">
        </div>
        <div class="contact-cont">
          <img alt="" class="icon" src="@/assets/images/header-phone.png"/>
          <div class="phone">{{ systemConfig ? systemConfig.mobile_400 : '' }}</div>
        </div>
      </div>

      <el-icon :class="[showMobileNav?'el-icon-s-fold':'el-icon-s-unfold',scrollTop > 10 ? '' :'cx-text-white']" style="font-size: 5rem"
               @click.native="showMobileNav = !showMobileNav"></el-icon>
      <div :class="{active:showMobileNav}" class="mobile-nav-list">
        <router-link
            v-for="(item,index) in navigationList"
            :key="index"
            :class="{active:item.path===$route.path}"
            :to="{path:item.path}"
            class="nav-item"
            tag="div">
          {{ item.name }}
          <div class="line"></div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {WOW} from 'wowjs'

export default {
  name: "custom-header",
  computed: {
    ...mapState(['navigationList', 'systemConfig', 'isMobile'])
  },
  data() {
    return {
      scrollTop: 0,
      headerLogo: require('@/assets/images/header-logo.png'),
      headerLogoActive: require('@/assets/images/header-logo-active.png'),
      showMobileNav: false,
    }
  },
  mounted() {
    this.listenToScrollTop();
    this.$nextTick(() => {
      new WOW().init();
    })
  },
  watch: {
    "$route.path"() {
      this.showMobileNav = false;
    }
  },
  methods: {
    listenToScrollTop() {
      this.scrollTop = document.documentElement.scrollTop;
      let that = this;
      window.addEventListener('scroll', function (e) {
        that.scrollTop = document.documentElement.scrollTop;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-cont {
  width: calc(100% - 22rem);
  padding: 0 11rem;
  height: 12rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.5s;

  .header-logo {
    width: 21rem;
    height: 5.3rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #ffffff;
      transition: all 0.5s;
      font-family: ArialMT;

      .nav-item {
        margin: 0 2.5rem;
        font-size: 2.2rem;
        font-weight: 400;
        cursor: pointer;

        .line {
          width: 0;
          height: 0.3rem;
          background-color: #005AAB;
          margin-top: 0.5rem;
          transition: all 0.5s;
        }

        &.active,
        &:hover {

          .line {
            width: 50%;
          }
        }

        &.active {
          font-weight: 600;
        }
      }
    }

    .contact-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #ffffff;
      transition: all 0.5s;

      .icon {
        width: 6rem;
        height: 6rem;
        margin-right: 1.5rem;
      }

      .phone {
        font-size: 2.8rem;
        font-weight: 100;
      }
    }
  }

  &.active {
    background-color: #ffffff;
    box-shadow: 0 0 10rem 0 rgba(0, 0, 0, 0.08);

    .content {
      .nav-list {
        color: #333333;
      }

      .contact-cont {
        color: #333333 !important;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .header-cont {
    padding: 0;
    width: 100%;
  }
}

.header-cont {
  .mobile-content {
    width: calc(100% - 10rem);
    height: 100%;
    padding: 0 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    //background-color: #ffffff;


    .contact-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #ffffff;
      transition: all 0.5s;
      margin-left: 10rem;

      .icon {
        width: 8rem;
        height: 8rem;
        margin-right: 1.5rem;
      }

      .phone {
        font-size: 2.8rem;
        font-weight: 100;
      }
    }


    .mobile-nav-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      position: absolute;
      left: 0;
      top: 12rem;
      max-height: 0;
      overflow: hidden;
      transition: .3s;

      .nav-item {
        width: 100%;
        text-align: center;
        line-height: 8rem;
      }

      &.active {
        transition: .3s;
        max-height: 1000rem;
      }
    }
  }

  &.active {
    .contact-cont {
      color: #333333 !important;
    }
  }
}

</style>
