<template>
  <div class="footer-cont">
    <div class="nav-cont" v-if="!isMobile">
      <img alt="" class="footer-logo" src="@/assets/images/footer-logo.png">

      <div class="nav-list">
        <router-link v-for="(item,index) in navigationList" :key="index" :to="{path:item.path}" class="nav-item" tag="div">{{ item.name }}</router-link>
      </div>
    </div>

    <div class="contact-cont" v-if="!isMobile">
      <div class="contact-item">
        <img alt="" src="@/assets/images/footer-icon-01.png">
        <div class="right-cont">
          <div class="title">商务咨询热线</div>
          <div class="phone">{{ systemConfig.mobile_400 }}</div>
        </div>
      </div>
      <div v-for="(item,index) in systemConfig.company" :key="index" class="contact-item">
        <img alt="" src="@/assets/images/footer-icon-02.png">
        <div class="right-cont">
          <div class="title">{{ item.title }}</div>
          <div class="address">{{ item.address }}</div>
        </div>
      </div>
    </div>

    <div class="bottom-cont">
      <div class="desc">您在哪里我们的服务就在哪里，全国范围内支持上门洽谈。</div>
      <div class="cont" :class="[isMobile?'cx-fex-column cx-fex-itemsl':'']">
        <div class="copyright">{{ systemConfig.copyright }}</div>
        <div class="beian" :style="{marginTop:`${isMobile?'3rem':'0'}`}"> 版权所有
          <a href="https://beian.miit.gov.cn/" v-if="domainSrc === 'vjiluo'">沪ICP备19006627号-5</a>
          <a href="https://beian.miit.gov.cn/" v-else-if="domainSrc === 'jiluoing'">沪ICP备19006627号-3</a>
          <a href="https://beian.miit.gov.cn/" v-else>{{ systemConfig ? systemConfig.beian : '' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {h} from "vue";

export default {
  data() {
    return {}
  },
  mounted() {
    console.log(window.location.href)
  },
  computed: {
    ...mapState(['navigationList', 'systemConfig','isMobile']),
    domainSrc() {
      let href = window.location.href;
      let _href = '';
      if(href.indexOf('vjiluo.com') !== -1){
        _href = 'vjiluo';
      }else if(href.indexOf('jiluoing.com') !== -1){
        _href='jiluoing';
      }
      return _href;
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-cont {
  padding: 0 11rem;
  background-color: #FBFBFB;

  .nav-cont {
    width: 100%;
    height: 14rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: .1rem solid #ECECEC;

    .footer-logo {
      width: 13.7rem;
      height: 10.8rem;
    }

    .nav-list {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .nav-item {
        font-family: "Arial";
        font-size: 2.2rem;
        margin-left: 4.4rem;
        cursor: pointer;
      }
    }
  }

  .contact-cont {
    display: flex;
    justify-content: space-between;
    padding: 9.5rem 0;

    .contact-item {
      display: flex;
      justify-content: flex-start;
      width: 20%;

      img {
        width: 5.3rem;
        height: 5.3rem;
        margin-right: 2.5rem;
      }

      .right-cont {
        flex: 1;

        .title {
          font-size: 1.6rem;
          color: #444444;
        }

        .phone {
          color: #333333;
          font-size: 3rem;
          margin-top: 1rem;
        }

        .address {
          font-size: 1.8rem;
          line-height: 2;
          color: #333333;
        }
      }
    }
  }

  .bottom-cont {
    .desc {
      padding: 3.5rem 0;
      border-bottom: .1rem solid #ECECEC;
      font-size: 1.8rem;
      color: #444444;
      font-weight: 500;
    }

    .cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 0;
      font-size: 1.6rem;
      color: #444444;

      a {
        color: #444444;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-cont{
    padding: 0 5rem;
  }
}
</style>
