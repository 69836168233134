export default {
    home:{
        section1: {
            firstTitle: '作品是',
            firstTitleBold: '最佳证言',
            secondTitle: '10年来只专注数字化移动应用开发这一件事',
            desc: '致力于为注重品牌策略和品牌形象的高要求客户提供网站设计、微信开发、APP开发、功能系统开发、数字营销、数字孪生服务。我们笃信创意与实效的双韧剑，追求创意设计、使用与营销的完美平衡点。通过商业策略、技术实现、情感连结融入互联网设计来创造具有活力 的品牌视觉及营销体验，结合线上资源来实现有效的价值传播，提升客户对品牌的价值信任，创造有价值的品牌营销体验。'
        },
        section2: {
            title:'从设计到开发，见证实力沉淀的过程',
            desc:'致力于为国内外品牌和企业提供极致的数字创意服务，为重塑新一代互联网用户体验，我们的奇思妙想帮助企业率先触摸未来。 我们的工作永远以客户为中心，从策略、创意、设计，到可扩展的品牌数字化、商务、移动和数字平台，我们愿始终于合作客户并肩作战， 联合创新，共同实现价值。'
        }
    },
    service:{
        firstTitle: '的网站建设服务商',
        firstTitleBold: '以策略为先导',
        secondTitle: '为客户品牌有效落地提供切实保障',
    },
    produce:{
        section1:{
            firstTitle: '',
            firstTitleBold: '用创新与智慧塑造未来',
            secondTitle: '创新缔造新价值，把握未来技术发展方向',
            desc:'多年深耕，3000+知名品牌客户。我们是以策略为先导的网站建设服务商。行业竞调与品牌研读构建互橙文化品牌竞争力，也为客户品牌有效落地提供切实保障。策略触发，文案出力，设计出彩，实现出色。\n集络网络自成立之初到现在发展成为精英企业，我们自身飞速发展的同时也见证着中国乃至世界互联网从起步到发展再到壮大。建立标准化的项目执行标准与项目测试标准，确保每个项目都经过严苛的标准测试后才交付给客户，这是我们对客户的承诺与责任。'
        },
        section2:{
            firstTitle: '集络网络专注在',
            secondTitle: '“用创新和智慧塑造未来”的愿景下，积极开展研究与开发',
            desc:'上海集络网络科技有限公司中国软件研发中心位于普陀区怒江北路红星世贸大厦的上海集络网络有限公司内，拥有500平方米全新的开发实验室和30多位合约程序员，并将计划在未来的5年内，中心的人员增至100人，以配合全中国各地区软件服务市场及技术研发的发展。\n我们在“用创新和智慧塑造未来”的愿景下，积极开展研究与开发 (R&D)，以确定新的未来增长领域，确保产品和服务的先进技术，创造新的价值，改善人们的生活。我们承诺将继续努力，通过不断的创新和智慧，成为创造未来新价值的全球优秀研究机构。'
        },
    },
    about: {
        section1:{
            firstTitle: '的网站建设服务商',
            firstTitleBold: '以策略为先导',
            secondTitle: '为客户品牌有效落地提供切实保障',
            desc:'专注，积淀每一刻信赖。创新，助力每一次飞跃集络网络，正以创新驾驭挑战成就时代赋予的重任。\n上海集络网络科技有限公司，成立于2014年。是一家提供计算机互联网领域内的网站建设、CRM，OA，ERP系统开发、APP开发，小程序开发，互联网营销等整合的高新技术企业。\n集络一直秉承“责任、进取、协作、共赢”的企业精神，坚持以客户为导向，为用户提供优异的产品和安全可靠的运营服务。经过多年的不懈努力，致力于成为计算机互联网领域的引领者，技术实力和服务水平已处于国内同行业领先地位。不懈的产品创新能力、强大的营销能力、专业化的研发运营能力不间断的7*24小时客户服务、良好的品牌口碑共同铸就了很大的竞争优势。'
        },
        section2:[
            {number:10,desc:'10余年创立经历'},
            {number:30,desc:'30余位工作伙伴'},
            {number:3000,desc:'3000多位合作客户'},
        ],
        section3:{
            firstTitle: '集络网络专注在',
            secondTitle: '用户体验设计与品牌数字革新',
            desc:'上海集络网络科技有限公司是一家提供计算机互联网领域内WEB应用开发、移动互联开发、软件开发、网站建设服务器托管，云主机等整合的数字传播机构。经过多年的不懈努力，公司技术实力和服务水平已处于国内同行业领先地位。\n 多年来，集络网络一直秉承“责任、进取、协作、共赢”的企业精神，坚持以客户为导向，为用户提供优异的产品和安全可靠的运营服务。不懈的产品创新能力、强大的营销能力、专业化的研发运营能力、不间断的7*24小时客户服务、良好的品牌口碑共同铸就了很大的竞争优势。'
        }
    }

}
