<template>
  <div class="__section-main__">
    <div class="home-banner-cont">
      <index-banner-cont :bannerList="bannerList"></index-banner-cont>
    </div>
    <div class="__container__">
      <div class="cx-fex-l cx-fex-itemsb" style="padding:20rem 0 7rem">
        <div class="common-page-title wow bounceInLeft" style="padding: 0">
          <div class="title"><span>以策略为先导</span> 的网站建设服务商</div>
          <div class="title">为客户品牌有效落地提供切实保障</div>
        </div>
        <div class="classify-list wow bounceInRight">
          <div
              v-for="(item,index) in classifyList"
              :key="index"
              :class="{active:currentClassifyIndex === index}"
              class="classify-item"
              @click="changeClassify(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="news-list">
      <div v-for="(item,index) in list" :key="index" class="news-item wow fadeInUp">
        <div class="left-cont">
          <div class="title" @click="toNewsDetailPage(item.id)">{{ item.title }}</div>
        </div>
        <div :style="{backgroundImage:`url(${item.image})`}" class="image-cont" @click="toNewsDetailPage(item.id)"></div>
        <div class="time-cont">
          <div class="day">{{ item.updateTimeStr.substring(8, 10) }}</div>
          <div class="time">{{ item.updateTimeStr.substring(0, 7) }}</div>
        </div>
      </div>
    </div>

    <contact-banner></contact-banner>
  </div>
</template>

<script>
import IndexBannerCont from "@/components/indexBannerCont";
import ContactBanner from "@/components/contact-banner";
import {mapState} from "vuex";
import {getNewsClassifyList, getNewsList} from "@/request/api";
import {WOW} from "wowjs"

export default {
  components: {ContactBanner, IndexBannerCont},
  metaInfo() {
    return {
      title: this.metaInfoData['4'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['4'].keywords},
        {name: 'description', content: this.metaInfoData['4'].description}
      ]
    }
  },
  computed: {
    ...mapState(['bannerData', 'metaInfoData']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['4'] && this.bannerData['4'].length > 0) {
        bannerList = this.bannerData['4'];
      }
      return bannerList
    }
  },
  data() {
    return {
      classifyList: [],
      currentClassifyIndex: 0,
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
      },
      getMore: false,
    }
  },
  mounted() {
    this.getNewsClassifyList();
    window.addEventListener('scroll', this.throttle(this.handleScroll, 500), true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttle(this.handleScroll, 500), true)
  },
  methods: {
    handleScroll() {
      let reduceHeight = (document.documentElement.offsetHeight - document.documentElement.clientHeight) * 0.63;
      let scrollTop = document.documentElement.scrollTop;
      if (reduceHeight < scrollTop && this.getMore) {
        this.getMore = false;
        this.getNewsList();
      } else {
        return false;
      }
    },
    throttle(func, wait) {
      let lastTime = null
      let timeout
      return () => {
        let context = this;
        let now = new Date();
        let arg = arguments;
        if (now - lastTime - wait > 0) {
          if (timeout) {
            clearTimeout(timeout)
            timeout = null
          }
          func.apply(context, arg)
          lastTime = now
        } else if (!timeout) {
          timeout = setTimeout(() => {
            func.apply(context, arg)
          }, wait)
        }
      }
    },
    toNewsDetailPage(id) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id
        }
      })
    },

    resetData() {
      this.getMore = true;
      this.listQuery.page = 1;
      this.list = [];
    },
    changeClassify(index) {
      this.resetData();
      this.currentClassifyIndex = index;
      this.getNewsList();
    },
    async getNewsClassifyList() {
      let res = await getNewsClassifyList();
      this.classifyList = res.data;
      this.classifyList.unshift({id: '', name: '全部'});
      await this.getNewsList();
      this.$nextTick(() => {
        new WOW().init();
      })
    },
    async getNewsList() {
      // if(!this.getMore) return false;
      let res = await getNewsList({cate: this.classifyList[this.currentClassifyIndex].id, ...this.listQuery});
      res.data.data.forEach(item => {
        item.updateTimeStr = this.$tool.getTimeStr(item.updatetime * 1000)
      })
      this.list = this.list.concat(res.data.data);
      this.listQuery.page++;
      this.getMore = this.listQuery.page > res.data.last_page ? false : true;
    }
  }
}
</script>

<style lang="scss" scoped>
.home-banner-cont {
  width: 100%;
  height: 100vh;
}

.classify-list {
  padding-left: 8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2rem;

  .classify-item {
    width: 13.5rem;
    height: 5.4rem;
    text-align: center;
    line-height: 5.4rem;
    font-size: 1.6rem;
    cursor: pointer;

    &.active {
      background-color: #F2F2F2;
    }
  }

}

.news-list {
  display: flex;
  flex-direction: column;

  .news-item {
    padding: 6rem 0;
    display: flex;
    background-color: #F8F8F8;
    transition: all 0.5s;

    .left-cont {
      flex: 1;
      padding: 6rem 25rem 6rem 18rem;

      .title {
        font-size: 3rem;
        line-height: 1.5;
        cursor: pointer;
      }
    }

    .image-cont {
      width: 64.2rem;
      height: 36rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .time-cont {
      width: 42rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .day {
        font-size: 3.6rem;
        line-height: 1;
        font-weight: 600;
      }

      .time {
        margin-top: 3rem;
        font-size: 1.6rem;
        line-height: 1;
      }
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      background: #ffffff;
    }

    &:hover {
      color: #005AAB;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-banner-cont {
    height: 50vh;
  }

  .classify-list{
    margin-top: 5rem;
    padding-left: 0;
  }

  .news-list {
    display: flex;
    flex-direction: column;

    .news-item {
      padding: 6rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #F8F8F8;
      transition: all 0.5s;

      .left-cont {
        flex: 1;
        padding: 6rem 5rem;

        .title {
          font-size: 3rem;
          line-height: 1.5;
          cursor: pointer;
        }
      }

      .image-cont {
        width: 64.2rem;
        height: 36rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .time-cont {
       display: none;
      }

      &:nth-of-type(2n) {
        flex-direction: column;
        background: #ffffff;
      }

      &:hover {
        color: #005AAB;
      }
    }
  }
}
</style>
