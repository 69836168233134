<template>
  <div class="content wow fadeInUp">
    <div class="cx-fex-l cx-fex-itemsl cx-fex-column">
      <div class="sub-title">{{ subTitle }}</div>
      <div class="title">{{ title }}</div>
    </div>
    <div class="flex-right">
      <div class="line"></div>
      <div class="more" @click="toMorePath()">view more</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "index-common-title",
  props: {
    subTitle: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: ''
    },
    path:{
      type:String,
      default:'',
    }
  },
  methods:{
    toMorePath() {
      if(!this.path) return false;
      this.$router.push({
        path:this.path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 0;

  .sub-title {
    color: #2c2c2c;
    font-size: 1.6rem;
    line-height: 1;
  }

  .title {
    font-size: 3.6rem;
    line-height: 1;
    font-weight: bold;
    margin-top: 2rem;
    color: #000000;
  }

  .flex-right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .line {
      //width: 50rem;
      flex:1;
      height: .2rem;
      background-color: #E0E0E0;
      margin-left: 15rem;
    }

    .more {
      font-size: 2rem;
      color: #2c2c2c;
      font-weight: 800;
      padding-left: 6rem;
      cursor: pointer;
    }
  }
}
</style>
