<template>
  <div class="__section-main__">
    <div v-if="bannerList.length > 0" :style="{backgroundImage:`url(${bannerList[0].image})`}" class="common-banner">
      <div class="banner-cont">
        <div v-for="(item,index) in bannerList[0].title.split(' ')" :key="index" class="title wow fadeInUp" data-wow-duration="2s">{{ item }}</div>
      </div>
    </div>

    <div class="__container__">
      <div class="common-page-title" style="padding-bottom: 10rem">
        <div class="title wow fadeInUp"><span>{{ globalData.produce.section1.firstTitleBold }}</span> {{ globalData.produce.section1.firstTitle }}</div>
        <div class="title wow fadeInUp">{{ globalData.produce.section1.secondTitle }}</div>
      </div>
      <div
          v-for="(item,index) in globalData.produce.section1.desc.split('\n')"
          :key="index"
          :style="{width:`${isMobile ? '100%' : '75%'}`}"
          class="common-page-desc wow fadeInUp"
      >
        {{ item }}
      </div>

      <!--  研发中心  start  -->
      <template v-if="environmentList.length > 0">
        <environment-list
            v-for="(item) in environmentList"
            :key="item.id"
            :desc="item.description"
            :list="item.dev_center"
            :title="item.cate_name"
        ></environment-list>
      </template>

      <!--  研发中心  end  -->

      <information-cont
          :desc="globalData.produce.section2.desc"
          :firstTitle="globalData.produce.section2.firstTitle"
          :secondTitle="globalData.produce.section2.secondTitle"
      ></information-cont>
    </div>

    <service-cont></service-cont>

    <contact-banner></contact-banner>
  </div>
</template>

<script>
import EnvironmentList from "@/components/environment-list";
import ContactBanner from "@/components/contact-banner";
import InformationCont from "@/components/information-cont";
import ServiceCont from "@/components/service-cont";
import {mapState} from "vuex";
import {getProduceList} from "@/request/api";
import {WOW} from 'wowjs'

export default {
  components: {ServiceCont, InformationCont, ContactBanner, EnvironmentList},
  metaInfo() {
    return {
      title: this.metaInfoData['3'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['3'].keywords},
        {name: 'description', content: this.metaInfoData['3'].description}
      ]
    }
  },
  computed: {
    ...mapState(['bannerData', 'globalData', 'metaInfoData', 'isMobile']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['3'] && this.bannerData['3'].length > 0) {
        bannerList = this.bannerData['3'];
      }
      return bannerList
    }
  },
  data() {
    return {
      environmentList: [],
    }
  },
  mounted() {
    this.getProduceList()
  },
  methods: {
    async getProduceList() {
      let res = await getProduceList();
      this.environmentList = res.data;
      this.$nextTick(() => {
        new WOW().init();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-cont {
  .title {
    font-size: 6rem !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) {

}
</style>
