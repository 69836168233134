<template>
  <div class="__section-main__">
    <div :style="{backgroundImage:`url(${bannerList[0].image})`}" class="common-banner">
      <div class="banner-cont">
        <div class="title wow fadeInUp" data-wow-duration="2s">{{ bannerList[0].title }}</div>
        <div v-for="(item,index) in bannerList[0].description.split(' ')" :key="index" :data-wow-delay="index/2 + 's'" class="sub-title wow fadeInUp"
             data-wow-duration="2s">{{ item }}
        </div>
      </div>
    </div>

    <div class="__container__" style="padding-bottom: 15rem">
      <div class="common-page-title">
        <div class="title wow fadeInUp" data-wow-duration="2s">作品是 <span>最佳证言</span></div>
        <div class="title wow fadeInUp" data-wow-duration="2s">为客户品牌有效落地提供切实保障</div>
      </div>

      <index-case-list2 :case-list="caseList" more-btn="none"></index-case-list2>
    </div>
  </div>
</template>

<script>
import IndexCaseList2 from "@/components/index-case-list2";
import {mapState} from "vuex";
import {WOW} from 'wowjs';
import {getCaseList} from "@/request/api";

export default {
  components: {IndexCaseList2},
  metaInfo() {
    return {
      title: this.metaInfoData['2'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['2'].keywords},
        {name: 'description', content: this.metaInfoData['2'].description}
      ]
    }
  },
  computed: {
    ...mapState(['metaInfoData', 'bannerData']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['2'] && this.bannerData['2'].length > 0) {
        bannerList = this.bannerData['2'];
      }
      return bannerList
    }
  },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 12,
      },
      getMore:true,
      caseList: []
    }
  },
  async mounted() {
    await this.getCaseList();
    window.addEventListener('scroll', this.throttle(this.handleScroll,500),true)
    this.$nextTick(() => {
      new WOW().init();
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.throttle(this.handleScroll, 500), true)
  },
  methods: {
    handleScroll() {
      let reduceHeight = (document.documentElement.offsetHeight -  document.documentElement.clientHeight) * 0.63;
      let scrollTop = document.documentElement.scrollTop;
      if(reduceHeight < scrollTop && this.getMore){
        this.getMore = false;
        this.getCaseList();
      }else{
        return false;
      }
    },
    throttle(func, wait) {
      let lastTime = null
      let timeout
      return () => {
        let context = this;
        let now = new Date();
        let arg = arguments;
        if (now - lastTime - wait > 0) {
          if (timeout) {
            clearTimeout(timeout)
            timeout = null
          }
          func.apply(context, arg)
          lastTime = now
        } else if (!timeout) {
          timeout = setTimeout(() => {
            func.apply(context, arg)
          }, wait)
        }
      }
    },
    async getCaseList() {
      let res = await getCaseList(this.listQuery);
      this.caseList = this.caseList.concat(res.data.list.data);
      this.listQuery.page ++;
      this.getMore = this.listQuery.page > res.data.list.last_page ? false : true;
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-cont {
  .title {
    background: linear-gradient(265deg, #F6AAB4 0%, #F5D1D1 51.220703125%, #FDFCFA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 9rem;
  }

  .sub-title {
    background: linear-gradient(265deg, #F6AAB4 0%, #F5D1D1 51.220703125%, #FDFCFA 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 5.6rem;
  }
}
</style>
