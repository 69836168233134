<template>
  <div>
    <div class="common-page-title2">
      <div class="title wow fadeInUp">{{ desc }}</div>
      <div class="title wow fadeInUp"><span>{{ title }}</span></div>
    </div>

    <div class="environment-cont">
      <div v-for="(item,index) in list" :key="index" :data-wow-delay="index/5 + 's'" class="environment-item wow fadeInUp">
        <div :style="{backgroundImage: `url(${item.image})`}" class="image-cont"></div>
        <div class="word-cont">
          <div class="index-cont">/ 0{{ index + 1 }}</div>
          <div class="right-cont">
            <div v-for="(titleItem,titleIndex) in item.title.split(' ')" :key="titleIndex" class="title">{{ titleItem }}</div>
            <div class="desc">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "environment-list",
  props: {
    desc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => ([]),
    }
  }
}
</script>

<style lang="scss" scoped>
.environment-cont {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10rem;

  .environment-item {
    width: 80.6rem;
    background-color: #F9F9F9;

    .image-cont {
      width: 100%;
      height: 39.9rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }

    .word-cont {

      padding: 9rem 15rem 7rem 10rem;
      display: flex;
      justify-content: space-between;

      .index-cont {
        font-size: 2rem;
        padding-right: 7rem;
        line-height: 2;
      }

      .right-cont {
        flex: 1;

        .title {
          font-size: 1.8rem;
          line-height: 2;
        }

        .desc {
          font-size: 1.6rem;
          line-height: 2;
          margin-top: 4rem;
          text-align: justify;
          color: #444444;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .environment-cont {
    flex-wrap: wrap;
    .environment-item {
      width: 100%;
      margin-bottom: 5rem;
      .word-cont{
        padding: 5rem;
      }
    }
  }
}
</style>
