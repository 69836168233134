<template>
  <div class="section-02">
    <div class="left-cont wow fadeInLeft">
      <div class="common-page-title2" style="padding-top: 0">
        <div class="title">{{ firstTitle }}</div>
        <div class="title"><span>{{ secondTitle }}</span></div>
      </div>

      <div class="common-page-desc" v-for="(item,index) in desc.split('\n')" :key="index">
        {{item}}
      </div>
    </div>
    <div :style="{backgroundImage:`url(${require('@/assets/resource/about-section-02.png')})`}" class="image-cont wow fadeInRight"></div>
  </div>
</template>

<script>
export default {
  name: "information-cont",
  props:{
    firstTitle:{
      type:String,
      default:''
    },
    secondTitle:{
      type:String,
      default:''
    },
    desc:{
      type:String,
      default:''
    },
  }
}
</script>

<style lang="scss" scoped>
.section-02 {
  display: flex;
  justify-content: space-between;
  padding-bottom: 7.5rem;

  .left-cont {
    flex: 1;
  }

  .image-cont {
    width: 73rem;
    height: 55rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-left: 17rem;
  }
}

@media screen and (max-width: 768px) {
  .section-02{
    flex-wrap: wrap;
    .left-cont {
      width: 100%;
    }

    .image-cont{
      width: 100%;
      margin-left: 0;
    }
  }
}
</style>
