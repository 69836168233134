<template>
  <div class="__section-main__">
    <div v-if="bannerList.length > 0" :style="{backgroundImage:`url(${bannerList[0].image})`}" class="common-banner">
      <div class="banner-cont">
        <div class="title wow fadeInUp">{{ bannerList[0].title }}</div>
        <div class="sub-title wow fadeInUp">{{ bannerList[0].description }}</div>
      </div>
    </div>

    <div class="__container__">
      <div class="common-page-title">
        <div class="title wow fadeInUp"><span>{{ globalData.service.firstTitleBold }}</span> {{ globalData.service.firstTitle }}</div>
        <div class="title wow fadeInUp">{{ globalData.service.secondTitle }}</div>
      </div>

      <div class="service-list">
        <div v-for="(item,index) in serviceList" :key="index" :data-wow-delay="index/10 + 's'" class="service-item wow fadeInUp" data-wow-duration="2s">
          <div :style="{backgroundImage: `url(${item.image})`}" class="image-cont"></div>
          <div class="right-cont">
            <div class="title">/0{{ index + 1 }} {{ item.title }}</div>
            <div class="desc-list">
              <div v-for="(tag,tagIndex) in item.tag" :key="tagIndex" class="desc-item">{{ tag }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getServiceList} from "@/request/api";
import {WOW} from 'wowjs'

export default {
  metaInfo() {
    return {
      title: this.metaInfoData['5'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['5'].keywords},
        {name: 'description', content: this.metaInfoData['5'].description}
      ]
    }
  },
  computed: {
    ...mapState(['globalData', 'bannerData', 'metaInfoData']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['5'] && this.bannerData['5'].length > 0) {
        bannerList = this.bannerData['5'];
      }
      return bannerList
    }
  },
  data() {
    return {
      serviceList: []
    }
  },
  mounted() {
    this.getServiceList();
  },
  methods: {
    async getServiceList() {
      let res = await getServiceList();
      this.serviceList = res.data;
      this.$nextTick(() => {
        new WOW().init();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.service-list {
  width: 100%;
  display: flex;
  flex-direction: column;

  .service-item {
    padding: 10rem 0;
    border-bottom: .1rem solid #E0E0E0;
    display: flex;
    justify-content: space-between;

    &:last-child {
      border-bottom: none;
    }

    &:first-child {
      padding-top: 0;
    }

    .image-cont {
      width: 68.1rem;
      height: 51.4rem;
      margin-right: 17.5rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .right-cont {
      flex: 1;

      .title {
        font-size: 3rem;
        color: #3d3d3d;
        padding-top: 4rem;
      }

      .desc-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 8.5rem;

        .desc-item {
          height: 5rem;
          line-height: 5rem;
          padding: 0 4rem;
          border: .1rem solid #666666;
          border-radius: 2.5rem;
          margin-right: 4rem;
          margin-bottom: 3rem;
          font-size: 1.6rem;
          color: #666666;
          transition: all 0.5s;
          cursor: default;

          &:hover {
            transform: translateY(-1rem);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .service-list {

    .service-item {
      flex-wrap: wrap;

      .image-cont {
        width: 100%;
        margin-right: 0;
      }

      .right-cont {
        width: 100%;
        margin-top: 10rem;
      }
    }
  }
}
</style>
