<template>
  <div v-if="value" class="contact-popup-cont">
    <div class="popup-bg"></div>
    <div class="popup-cont animated bounceInDown">
      <div class="popup-header">
        <div>免费获取方案</div>
        <img alt="" src="@/assets/images/close-icon.png" @click="$emit('input',false)">
      </div>
      <div class="popup-main">
        <div class="form-item">
          <div class="label">贵公司的网址是？</div>
          <input v-model="formData.website" placeholder="贵公司目前的官网网址（如没有可不填）" type="text">
        </div>
        <div class="form-item">
          <div class="label">如何称呼您？</div>
          <input v-model="formData.name" placeholder="请输入您的称呼" type="text">
        </div>
        <div class="form-item">
          <div class="label">您的联系方式是？</div>
          <input v-model="formData.tel" placeholder="请输入您的手机号码" type="text">
        </div>
        <div class="form-item">
          <div class="label">留言</div>
          <textarea v-model="formData.content" placeholder="和我们聊聊您的需求？"></textarea>
        </div>

        <div class="submit-btn" @click="handleSubmit()">立即提交获取方案</div>
      </div>
    </div>
  </div>
</template>

<script>
import {contactSubmit} from "@/request/api";

export default {
  name: "contactPopup",
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      formData: {
        website: '',
        name: '',
        tel: '',
        content: '',
      }
    }
  },
  methods: {
    async handleSubmit() {
      if (this.checkFormData()) {
        let res = await contactSubmit(this.formData);
        this.$message.success(res.msg);
        this.$emit('input', false);

      }
    },
    checkFormData() {
      if (!this.formData.name) {
        this.$message.error('请输入您的称呼');
        return false
      } else if (!this.$tool.verifyPhone(this.formData.tel)) {
        this.$message.error('请输入正确的手机号码');
        return false
      } else if (!this.formData.content) {
        this.$message.error('请输入您的留言');
        return false
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-popup-cont {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1100;

  .popup-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup-cont {
    position: absolute;
    top: 5rem;
    left: 50%;
    margin-left: -32.5rem;
    //transform: translate(-50%, -50%);


    .popup-header {
      width: calc(65rem - 10rem);
      height: 9.4rem;
      padding: 0 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ffffff;
      font-size: 3rem;
      background-color: #2C2C2C;

      img {
        width: 2.8rem;
        height: 2.8rem;
        cursor: pointer;
      }
    }

    .popup-main {
      width: calc(65rem - 10rem);
      padding: 1rem 5rem 4rem;
      background-color: #F5F5F5;

      .form-item {
        padding-top: 3rem;

        .label {
          color: #333333;
          font-size: 1.8rem;
          margin-bottom: 2rem;
        }

        input {
          width: calc(100% - 5rem);
          height: 6rem;
          padding: 0 2.5rem;
          line-height: 6rem;
          border: none;
          outline: none;
          background-color: #ffffff;
          border-radius: 3rem;
          font-size: 1.6rem;
        }

        textarea {
          width: calc(100% - 5rem);
          height: 7.8rem;
          padding: 2.2rem 2.5rem;
          background-color: #ffffff;
          border: none;
          outline: none;
          font-size: 1.6rem;
          resize: none;
        }
      }

      .submit-btn {
        width: 100%;
        height: 6rem;
        line-height: 6rem;
        background-color: #005AAB;
        text-align: center;
        color: #ffffff;
        border-radius: 3rem;
        font-size: 1.8rem;
        margin-top: 3rem;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-popup-cont {

    .popup-cont {
      top: 20%;
      margin-left: -42.5rem;

      .popup-header {
        width: calc(85rem - 10rem);
      }

      .popup-main {
        width: calc(85rem - 10rem);

        .submit-btn {
          height: 8rem;
          line-height: 8rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
