<template>
  <div class="__section-main__">
    <div v-if="bannerList.length > 0" :style="{backgroundImage:`url(${bannerList[0].image})`}" class="common-banner">
      <div class="banner-cont">
        <div v-for="(item,index) in bannerList[0].title.split(' ')" :key="index" class="title wow fadeInUp" data-wow-duration="2s">{{ item }}</div>
      </div>
    </div>

    <div class="__container__">
      <div class="common-page-title" style="padding-bottom: 10rem">
        <div class="title wow fadeInUp"><span>{{ globalData.about.section1.firstTitleBold }}</span> {{ globalData.about.section1.firstTitle }}</div>
        <div class="title wow fadeInUp">{{ globalData.about.section1.secondTitle }}</div>
      </div>
      <div
          v-for="(item,index) in globalData.about.section1.desc.split('\n')"
          :key="index"
          :style="{width: `${isMobile ? '100%' : '75%'}`}"
          class="common-page-desc wow fadeInUp"
      >
        {{ item }}
      </div>

      <environment-list
          v-if="environmentItem"
          :desc="environmentItem.description"
          :list="environmentItem.dev_center"
          :title="environmentItem.cate_name"
      ></environment-list>

    </div>
    <div :style="{backgroundImage:`url(${require('@/assets/resource/about-second-banner.png')})`}" class="second-banner"></div>

    <div class="__container__  wow fadeInUp">
      <div class="section-01">
        <div v-for="(item,index) in globalData.about.section2" :key="index" class="item">
          <div class="number-cont"><span>{{ item.number }}</span>+</div>
          <div class="desc">{{ item.desc }}</div>
        </div>

      </div>

      <information-cont
          :desc="globalData.about.section3.desc"
          :first-title="globalData.about.section3.firstTitle"
          :second-title="globalData.about.section3.secondTitle"
      ></information-cont>
    </div>


    <div class="__container__" style="background-color: #ffffff" :style="{padding:`${isMobile?'5rem':'11rem'}`}">
      <index-case-list2 :case-list="caseList" moreBtn="none"></index-case-list2>
    </div>

    <service-cont></service-cont>

    <contact-banner></contact-banner>

  </div>
</template>

<script>
import IndexCaseList2 from "@/components/index-case-list2";
import ContactBanner from "@/components/contact-banner";
import EnvironmentList from "@/components/environment-list";
import ServiceCont from "@/components/service-cont";
import InformationCont from "@/components/information-cont";
import {getProduceList, getHomeCaseData} from "@/request/api";
import {mapState} from "vuex";
import {WOW} from "wowjs"

export default {
  components: {InformationCont, ServiceCont, EnvironmentList, ContactBanner, IndexCaseList2},
  metaInfo() {
    return {
      title: this.metaInfoData['7'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['7'].keywords},
        {name: 'description', content: this.metaInfoData['7'].description}
      ],
    }
  },
  computed: {
    ...mapState(['globalData', 'metaInfoData', 'bannerData', 'isMobile']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['6'] && this.bannerData['6'].length > 0) {
        bannerList = this.bannerData['6'];
      }
      return bannerList
    }
  },
  data() {
    return {
      environmentItem: null,
      caseList: [],
    }
  },
  async mounted() {
    await this.getProduceList();
    await this.getHomeCaseData();
    this.$nextTick(() => {
      new WOW().init();
    })
  },
  methods: {
    async getProduceList() {
      let res = await getProduceList();
      this.environmentItem = res.data[0];
    },
    async getHomeCaseData() {
      let res = await getHomeCaseData({page: 1, limit: 8});
      this.caseList = res.data.casesList.data;
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-cont {
  .title {
    font-size: 6rem !important;
    font-weight: 500 !important;
    margin-bottom: 2rem !important;
  }
}


.second-banner {
  width: 100%;
  height: 65.7rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.section-01 {
  width: 100%;
  padding: 5rem 0;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 0 .5rem 20rem .8rem rgba(0, 0, 0, 0.05);
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  .item {
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-right: .1rem solid #DBDADA;

    &:last-child {
      border-right: none;
    }

    .number-cont {
      font-size: 4rem;
      line-height: 1;
      font-weight: bold;
    }

    .desc {
      font-size: 2rem;
      margin-top: 3rem;
    }
  }
}


</style>
