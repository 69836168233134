<template>
  <div class="__section-main__">
    <template v-if="detail">
      <div class="banner-cont">
        <div
            :style="{backgroundImage:`url(${detail.image})`}"
            class="banner-item"
        >
          <div class="mark"></div>
          <div class="word-cont">
            <div class="keyword">
              <span v-for="(item,index) in detail.keywords.split(',')" :key="index">{{ item }}</span>
            </div>
            <div class="cx-fex-l">
              <div class="title">{{ detail.title }}</div>
            </div>

            <div class="desc-cont">
              <div v-for="(item,index) in detail.description.split(' ')" :key="index" class="desc">{{ item }}</div>
            </div>
          </div>
        </div>


        <div class="scroll-down">
          <div class="word">Scroll Down</div>
          <div class="icon-cont">
            <div class="triangle"></div>
            <div class="line"></div>
          </div>
        </div>
      </div>

      <div class="detail-cont">
        <div class="content" v-html="detail.content"></div>

        <div class="end-tip">Case View End</div>

        <div class="button-cont">
          <div class="btn prev" :class="{active:detail.prev_id}" @click="getCaseDetailById(detail.prev_id)">
            <img :src="detail.prev_id ? arrowActiveImage : arrowImage" alt=""/>Prev
          </div>
          <div class="btn next" :class="{active:detail.next_id}" @click="getCaseDetailById(detail.next_id)">
            Next<img :src="detail.next_id ? arrowActiveImage : arrowImage" alt=""/>
          </div>
        </div>
      </div>

    </template>


  </div>
</template>

<script>
import {getCaseDetail} from "@/request/api";

export default {
  metaInfo() {
    return {
      title: this.detail.title,
      meta: [
        {name: 'keywords', content: this.detail.keywords},
        {name: 'description', content: this.detail.description}
      ]
    }
  },
  data() {
    return {
      detail: {
        title:'',
        description:'',
        keywords:'',
      },
      arrowImage: require('@/assets/images/case-detail-arrow.png'),
      arrowActiveImage: require('@/assets/images/case-detail-arrow-active.png'),
    }
  },
  mounted() {
    const caseId = this.$route.query.id ? this.$route.query.id : '';
    this.getCaseDetail(caseId);
  },
  methods: {
    getCaseDetailById(id){
      if(!id) return false;
      window.document.documentElement.scrollTop = 0;
      this.getCaseDetail(id);
    },
    async getCaseDetail(id) {
      let res = await getCaseDetail({id});
      this.detail = res.data;
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes moveTopBottom {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

.banner-cont {
  width: 100%;
  height: 100vh;
  position: relative;

  .banner-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 1s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .mark {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
    }

    .word-cont {
      color: #ffffff;
      position: absolute;
      left: 0;
      bottom: 15rem;
      padding: 0 15rem;
      width: calc(100% - 30rem);

      .keyword {
        font-size: 1.8rem;
        opacity: 0.7;

        span {
          margin-right: 1rem;
        }
      }

      .title {
        font-size: 5rem;
        font-weight: 500;
        margin-top: 5rem;
        line-height: 1;
        padding-bottom: 7rem;
        border-bottom: .1rem solid #848484;
        padding-right: 5rem;
      }

      .desc-cont {
        margin-top: 5rem;
        font-size: 1.8rem;
        line-height: 2.5;
        opacity: 0.7;
      }
    }

  }

  .scroll-down {
    position: absolute;
    left: 6.7rem;
    bottom: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .word {
      color: #58595B;
      font-size: 1.6rem;
      writing-mode: vertical-rl;
      text-orientation: sideways;
    }

    .icon-cont {
      animation: moveTopBottom 1.5s ease-in-out infinite;
      margin-top: 2rem;
      margin-right: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .triangle {
        width: 0;
        height: 0;
        border: 0.6rem solid transparent;
        border-top-color: #005AAB;
      }

      .line {
        transform: translateY(-0.6rem);
        width: .1rem;
        height: 17.4rem;
        background-color: #444445;
      }
    }
  }
}

.detail-cont {
  padding: 17.5rem 15rem 0;
  background-color: #FAF8F5;

  .end-tip {
    padding: 12rem;
    border-bottom: .3rem solid #000000;
    font-size: 2rem;
    text-align: center;
  }

  .button-cont {
    padding: 13rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
      margin: 0 2rem;
      width: 25rem;
      height: 10rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: .2rem solid #999999;
      font-size: 1.8rem;
      font-family: ArialMT;
      cursor: pointer;

      &.prev {
        img {
          margin-right: 5rem;
        }
      }

      &.next {
        img {
          margin-left: 5rem;
          transform: rotateY(180deg);
        }
      }

      &.active {
        border-color: #005AAB;
        color: #005AAB;

        &.prev {
          img {
            transform: rotateY(180deg);
          }
        }
        &.next {
          img {
            transform: rotateY(0deg);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .detail-cont{
    padding: 17.5rem 5rem 0;
  }
}
</style>
