// 测试
// export const BASE_URL = 'http://demo.cms.jiluoing.com';
// export const IMAGE_PATH = 'http://demo.cms.jiluoing.com/storage/';


// 集络
export const BASE_URL = 'https://cms.ht.jiluoing.com';
export const IMAGE_PATH = 'https://cms.ht.jiluoing.com/storage/';


// // 湘岳
// export const BASE_URL = 'http://cms.ht.xiangyuesoft.cn';
// export const IMAGE_PATH = 'http://cms.ht.xiangyuesoft.cn/storage/';

