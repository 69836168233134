import request from "@/utils/request"

export const getSystemConfig = (params) => {
    return request.get(`/api/common/system`, {params});
}
export const getSeoList = (params) => {
    return request.get(`/api/common/seo`, {params});
}

//获取所有banner列表
export const getBannerList = (params) => {
    return request.get(`/api/common/banner`, {params});
}

//获取服务列表
export const getServiceList = (params) => {
    return request.get(`/api/employ/list`, {params});
}

//获取研发中心列表
export const getProduceList = (params) => {
    return request.get(`/api/dev_center/list`, {params});
}

//获取智库分类列表
export const getNewsClassifyList = (params) => {
    return request.get(`/api/think_tanks/cate`, {params});
}
//获取智库分类列表
export const getNewsList = (params) => {
    return request.get(`/api/think_tanks/list`, {params});
}
//获取智库分类列表
export const getNewsDetail = (params) => {
    return request.get(`/api/think_tanks/details`, {params});
}

//获取业务介绍列表
export const getBusinessList = (params) => {
    return request.get(`/api/business/list`, {params});
}

// 获取案例首页数据
export const getHomeCaseData = (params) => {
    return request.get(`/api/cases/homePage`, {params});
}
//获取案例列表
export const getCaseList = (params) => {
    return request.get(`/api/cases/list`, {params})
}
//获取案例详情
export const getCaseDetail = (params) => {
    return request.get(`/api/cases/detail`, {params})
}


//用户咨询信息提交
export const contactSubmit = (data) => {
    return request.post(`/api/user_ask/submit`, data)
}
