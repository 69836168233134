<template>
  <div class="__section-main__">
    <div v-if="bannerList.length > 0" :style="{height:`${isMobile?'50vh':'100vh'}`}" class="home-banner-cont">
      <index-banner-cont :banner-list="bannerList"></index-banner-cont>
    </div>

    <div class="__container__">
      <div class="section-01">
        <div class="title wow fadeInUp">{{ globalData.home.section1.firstTitle }} <span>{{ globalData.home.section1.firstTitleBold }}</span></div>
        <div class="title wow fadeInUp">{{ globalData.home.section1.secondTitle }}</div>
        <div class="desc wow fadeInUp">{{ globalData.home.section1.desc }}
        </div>
      </div>
    </div>
    <template v-if="caseTypeList.length > 0">
      <div v-for="(item,index) in caseTypeList" :key="index" class="__container__">
        <index-common-title :sub-title="item.description" :title="item.name" path="/case"></index-common-title>
        <index-case-list1 :case-list="item.cases"></index-case-list1>
      </div>
    </template>


    <div :style="{backgroundImage:`url(${require('@/assets/resource/index-second-banner.png')})`}" class="second-banner"></div>

    <div class="__container__">
      <div class="section-02">
        <div class="title wow fadeInUp">{{ globalData.home.section2.title }}</div>
        <div class="desc wow fadeInUp">{{ globalData.home.section2.desc }}</div>
      </div>
      <index-case-list2 :case-list="caseList"></index-case-list2>
    </div>

    <div class="__container__">
      <div class="section-03">
        <div class="title-cont wow fadeInUp">
          <div class="title">核心服务</div>
          <div class="sub-title">高端定制~ 个性化开发服务</div>
        </div>

        <div class="service-list">
          <div v-for="(item,index) in businessList" :key="index" :data-wow-delay="index/10 + 's'" class="list-item wow fadeInUp">
            <div :style="{backgroundImage: `url(${require('@/assets/images/index-service-item-bg.png')})`}" class="image-cont">
              <img :src="item.icon_image" alt="">
            </div>
            <div class="title">{{ item.cate_name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div :style="{backgroundImage:`url(${require('@/assets/images/index-second-banner-02.png')})`}" class="second-banner">
      <div class="banner-cont">
        <div class="title wow fadeInUp">承载战略，驱动业务</div>
        <div class="more-btn wow fadeInUp" @click="popupVisible = true">查看更多</div>
      </div>
    </div>

    <contact-popup v-model="popupVisible"></contact-popup>
  </div>
</template>

<script>
import IndexBannerCont from "@/components/indexBannerCont";
import IndexCommonTitle from "@/components/index-common-title";
import IndexCaseList1 from "@/components/index-case-list1";
import IndexCaseList2 from "@/components/index-case-list2";
import {getBusinessList, getHomeCaseData} from "@/request/api";
import {mapState} from "vuex";
import ContactPopup from "@/components/contactPopup";

export default {
  components: {ContactPopup, IndexCaseList2, IndexCaseList1, IndexCommonTitle, IndexBannerCont},
  metaInfo() {
    return {
      title: this.metaInfoData['1'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['1'].keywords},
        {name: 'description', content: this.metaInfoData['1'].description}
      ],
    }
  },
  computed: {
    ...mapState(['globalData', "bannerData", 'metaInfoData', 'isMobile']),
    bannerList() {
      let bannerList = [];
      if (this.bannerData && this.bannerData['1'] && this.bannerData['1'].length > 0) {
        bannerList = this.bannerData['1'];
      }
      return bannerList
    }
  },
  data() {
    return {
      caseTypeList: [],
      caseList: [],
      businessList: [],
      popupVisible: false,
    }
  },
  mounted() {
    this.getHomeCaseData();
    this.getBusinessList();
  },
  methods: {
    //获取业务列表
    async getBusinessList() {
      let res = await getBusinessList();
      this.businessList = res.data;
    },
    //获取首页案例
    async getHomeCaseData() {
      let res = await getHomeCaseData({page: 1, limit: 16});
      this.caseTypeList = res.data.casesTypeList;
      this.caseList = res.data.casesList.data;

      console.log(this.caseTypeList)
      console.log(this.caseList)
    }
  }
}
</script>

<style lang="scss" scoped>
.home-banner-cont {
  width: 100%;
  height: 100vh;
}

.section-01 {
  padding: 15rem 0 10rem;
  width: 50%;

  .title {
    font-size: 4.2rem;
    line-height: 1;

    span {
      font-weight: bold;
    }

    &:nth-of-type(2) {
      margin-top: 3.5rem;
    }
  }

  .desc {
    font-size: 1.6rem;
    line-height: 2.5;
    color: #5E6464;
    margin-top: 10rem;
    text-align: justify;
  }
}

.section-02 {
  padding: 8.8rem 0 8.8rem 14rem;
  width: 50%;

  .title {
    font-size: 3.2rem;
    color: #2c2c2c;
    line-height: 1;
  }

  .desc {
    color: #999999;
    font-size: 1.6rem;
    line-height: 2.5;
    margin-top: 6rem;
  }
}

.second-banner {
  width: 100%;
  height: 56rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;

  .banner-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.3);

    .title {
      font-size: 6rem;
      font-weight: 500;
      color: #ffffff;
    }

    .more-btn {
      width: 28.5rem;
      height: 6rem;
      text-align: center;
      background-color: #ffffff;
      line-height: 6rem;
      font-size: 1.6rem;
      color: #444444;
      margin-top: 6rem;
      cursor: pointer;
    }
  }
}

.section-03 {
  .title-cont {
    padding: 3rem 0 13.5rem;

    .title {
      font-size: 3.2rem;
      font-weight: 500;
      line-height: 0;
    }

    .sub-title {
      font-size: 2.4rem;
      line-height: 1;
      margin-top: 5rem;
    }
  }

  .service-list {
    display: flex;
    justify-content: space-between;
    padding-bottom: 9rem;

    .list-item {
      width: 22.2rem;
      display: flex;
      flex-direction: column;
      transition: all 0.5s;

      &:hover {
        transform: translateY(-2rem);
      }

      .image-cont {
        width: 22.2rem;
        height: 19.2rem;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 90%;
          max-height: 90%;
        }
      }

      .title {
        font-size: 1.6rem;
        line-height: 1;
        margin-top: 4.5rem;
        text-align: center;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .section-01 {
    width: 100%;
  }

  .section-02{
    padding: 8.8rem 0 8.8rem 0;
    width: 100%;
  }

  .section-03{
    margin-top: 10rem;
    .service-list{
      flex-wrap: wrap;
      .list-item{
        width: 50%;
        justify-content: center;
        align-items: center;
        margin-bottom: 5rem;
      }
    }
  }
}
</style>
