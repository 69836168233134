<template>
  <div class="__section-main__">
    <!--  <div class="common-banner" :style="{backgroundImage:`url(${require('@/assets/resource/produce-banner.png')})`}"></div>-->
    <div :style="{backgroundImage:`url(${require('@/assets/resource/contact-banner.png')})`}" class="contact-cont">

      <div class="__container__ main-cont">
        <div class="cx-fex " :class="[isMobile?'cx-fex-column':'cx-fex-itemsc']">
          <div class="contact-us wow fadeInUp" data-wow-duration="2s">
            <div class="title">Contact Us <span></span></div>
            <div class="desc">素未谋面，却能心照不宣</div>
          </div>

          <div class="company-list">
            <div v-for="(item,index) in systemConfig.company" :key="index" class="company-item wow fadeInUp" :data-wow-delay="index/10 + 's'" data-wow-duration="2s">
              <div class="title">{{ item.title }}</div>
              <div class="address">{{ item.address }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-desc-cont wow fadeInUp" data-wow-duration="2s">您在哪里我们的服务就在哪里，全国范围内支持上门洽谈。</div>
    </div>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {WOW} from 'wowjs';

export default {
  metaInfo() {
    return {
      title: this.metaInfoData['6'].title,
      meta: [
        {name: 'keywords', content: this.metaInfoData['6'].keywords},
        {name: 'description', content: this.metaInfoData['6'].description}
      ]
    }
  },
  computed: {
    ...mapState(['metaInfoData', 'systemConfig','isMobile'])
  },
  mounted() {
    this.$nextTick(() => {
      new WOW().init();
    })
  }
}
</script>

<style lang="scss" scoped>
.contact-cont {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  .main-cont {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: calc(100% - 22rem);
  }

  .contact-us {
    color: #ffffff;

    .title {
      font-size: 3.6rem;
      font-weight: 500;

      span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background-color: #005AAB;
      }
    }

    .desc {
      font-size: 2.4rem;
      margin-top: 4rem;
    }
  }

  .company-list {
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .company-item {
      margin-left: 13rem;
      max-width: 22.5rem;

      .title {
        font-size: 2rem;
      }

      .address {
        margin-top: 2rem;
        font-size: 1.6rem;
        line-height: 2;
      }
    }
  }

  .contact-desc-cont {
    position: absolute;
    left: 11rem;
    bottom: 12.5rem;
    font-size: 2rem;
    color: #ffffff;
  }
}

@media screen and (max-width: 768px) {
  .contact-cont{
    .main-cont{
      .company-list{
        justify-content: flex-start;
        flex-direction: column;

        .company-item{
          margin-top: 10rem;
          width: 100%;
          max-width: 100%;
          margin-left: 0;
        }

      }
    }
  }
}
</style>
